<template>
  <v-autocomplete
    label="Sucursal Destino"
    placeholder="Seleccione una cuenta"
    :loading="loading"
    :items="items"
    item-text="destination_company.second_name"
    item-value="id"
    return-object
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :search-input.sync="search"
    ref="auto"
    clearable
    :rules="isRequired ? onlyRequired : []"
  >
  </v-autocomplete>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules.js";
export default {
  props: {
    reset: { type: Boolean, required: false },
    value: [Object],
    reload: [Boolean],
    isRequired: { type: Boolean, required: false, default: false },
    sourceCompany: { type: Number, required: true },
  },
  data: () => ({
    loading: true,
    items: [],
    search: "",
    onlyRequired: [rules.required],
  }),
  methods: {
    getInternalAccount(param = "") {
      this.loading = true;
      if (this.sourceCompany > 0) {
        let uri =
          "/api/bussiness-connector/internal-accounts/?source_company=" +
          this.sourceCompany +
          "&search=";
        if (this.search) {
          uri += param;
        }
        requests.get(uri).then((res) => {
          if (res.status == 200) {
            this.items = res.data.results;
            this.loading = false;
          } else {
            console.log(res);
          }
        });
      } else {
        this.items = [];
        this.loading = false;
      }
    },
  },
  watch: {
    sourceCompany: function (newValue) {
      if (newValue > 0) {
        this.getInternalAccount();
      }
    },
    reset: function () {
      this.$refs.auto.reset();
    },
    reload: function () {
      this.getInternalAccount();
    },
    search: function () {
      this.getInternalAccount(this.search);
    },
  },
  mounted() {
    this.getInternalAccount();
  },
};
</script>
